import React, { Component, useEffect } from "react";
import { Topbar } from "../topbar";
import { Banner } from "../banner";
import { useLocation } from "react-router-dom";

export const ReservationDetails = () => {
    const location = useLocation();
    var resp = location.state.cJSON;
    

    return(
        <>
            <Topbar />
                <Banner />
                <div className="container-fluid pt-5">
                    <h1 className="text-primary text-center">RESERVATION INFORMATION</h1>
                    <div className="col-12 row">
                        <div className="col-12">
                            <h2>Information</h2>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Reservation code: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.reservation_code}</p>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Name: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.firstName}</p>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Payment Method: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.payment_type}</p>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">E-mail: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.email}</p>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Phone: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.phone}</p>
                        </div>
                        <div className="col-12">
                            <h2>Arrival</h2>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Airline arrival: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.airlineArrival}</p>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Destination: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.hotel_s}</p>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Passengers: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.passenger_s}</p>
                        </div>
                        <div className="col-12">
                            <h2>Departure</h2>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Airline departure: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">{resp.airlineDeparture}</p>
                        </div>
                        <div className="col-3">
                            <p className="col-form-label-lg alert-link">Total: </p>
                        </div>
                        <div className="col-9">
                            <p className="col-form-label-lg">${resp.total_s}</p>
                        </div>
                    </div>
                </div>
        </>
    );
}