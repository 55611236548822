import React, { useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm, useFormState } from 'react-hook-form';
import '../../styles/formRegistro.css';
import { Topbar } from '../topbar';
import { Banner } from '../banner';
import { TimePick } from '../lib/TimePick';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";

export const FormRegistro = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    let resp = location.state.cJSON;
    const {register, formState: {errors}, handleSubmit, control} = useForm();
    let element_paypal = document.querySelector("#btn_paypal");
    let fieldError = 'This field is required';    
    useEffect(() => {
        if(element_paypal ==  null){
            var s = document.createElement('script');
            s.id="btn_paypal";
            s.type = 'text/javascript';
            s.appendChild(document.createTextNode(resp.bton_paypal));
            document.body.appendChild(s);
        }
    }, []);
    
    const onSubmit = async (datos) => {
        var serialize = require('form-serialize');
        var form = document.querySelector("#form-reservation-send");
        var data = serialize(form, {hash: true});
        const protocol = window.location.protocol;
        const host = window.location.host;
        var aData = {
                data
        };
        const response = await axios.post(process.env.REACT_APP_API_URL+'create', aData)
        .then( (response)=> {
            Swal.fire({
                title: "Reservation successful!",
                text: "Thank you for booking with us",
                icon: "success",
                confirmButtonText: 'Continue'
            })
            .then(function(isConfirm){
                // localStorage.setItem('set_compra', JSON.stringify(response.data.data));
                // window.location.href= protocol + "//" + host +"/Reservation";
                navigate('/Reservation',{
                    state: {
                        cJSON: response.data.data,
                    }
                });
            });

        })
        .catch( (e) => {
            Swal.fire({
                title: "Oops...",
                icon: "error",
                text: "Something went wrong!",
            });
        });
        
    };
    
    return (
        <>
        <Topbar />
        <Banner />
        <div className='container'>
            <div className="contact__wrapper shadow-lg mt-n9">
                <div className="row no-gutters">
                    <div className="col-lg-5 contact-info__wrapper gradient-brand-color p-5 order-lg-2">
                        <h2 className='text-white'>{t("formRegistro.information")}</h2>
                        <ul className="contact-info__list list-style--none position-relative z-index-101">
                            { typeof resp.Hotel !== 'undefined' ?
                                <li className="mb-4 pl-1 list-group">
                                    <span className="position-absolute"><i className="fas fa-plane"></i></span>Hotel: {resp.Hotel}
                                </li>
                                : ''
                            }
                            { typeof resp.PagoTotal !== 'undefined' ?
                                <li className="mb-4 pl-1 list-group">
                                    <span className="position-absolute"><i className="fas fa-money-bill-alt"></i></span>Total: ${resp.PagoTotal} USD
                                </li>
                                : ''
                            }
                            { typeof resp.pax !== 'undefined' ?
                                <li className='mb-4 pl-1 list-group'>
                                    <span className='position-absolute'><i className="fas fa-plane"></i></span>{t("formRegistro.passengers")}: {resp.pax}
                                </li>
                                : ''
                            }
                            
                        </ul>
                        
                    </div>
            
                    <div className="col-lg-7 contact-form__wrapper p-5 order-lg-1">
                        <form action="#" className="contact-form form-validate" id="form-reservation-send" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <div className="form-group">
                                        <label className="required-field">{t("formRegistro.first_name")}</label>
                                        <input type="text" className="form-control" id="firstName" {...register('firstName',{required: fieldError} )} />
                                        <input type="hidden" className="form-control" id="hotel_s" {...register('hotel_s')} value={resp.Hotel}/>
                                        <input type="hidden" className="form-control" id="total_s" {...register('total_s')} value={resp.PagoTotal}/>
                                        <input type="hidden" className="form-control" id="passenger_s" {...register('passenger_s')} value={resp.pax}/>
                                        <input type="hidden" className="form-control" id="departure_date_s" {...register('departure_date_s')} value={resp.departure_date}/>
                                        <input type="hidden" className="form-control" id="arrival_date_s" {...register('arrival_date_s')} value={resp.arrival_date}/>
                                        <input type="hidden" className="form-control" id="type_trip_s" {...register('type_trip_s')} value={resp.type_trip}/>
                                        <input type="hidden" className="form-control" id="zone_s" {...register('zone_s')} value={resp.zone_id}/>
                                    </div>
                                    { errors.firstName && <p className='text-danger'>{errors.firstName.message}</p> }
                                </div>
            
                                <div className="col-sm-6 mb-3">
                                    <div className="form-group">
                                        <label>{t("formRegistro.last_name")}</label>
                                        <input type="text" className="form-control" id="lastName" {...register('lastName',{required: fieldError} )} />
                                    </div>
                                    { errors.lastName && <p className='text-danger'>{errors.lastName.message}</p> }
                                </div>
            
                                <div className="col-sm-6 mb-3">
                                    <div className="form-group">
                                        <label className="required-field" >Email</label>
                                        <input type="text" className="form-control" id="email" {...register("email", {
                                            required: "Email is required",
                                            validate: {
                                                maxLength: (v) =>
                                                v.length <= 50 || "The email should have at most 50 characters",
                                                matchPattern: (v) =>
                                                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                "Email address must be a valid address",
                                            },
                                        })}
                                        />
                                    </div>
                                    { errors.email && <p className='text-danger'>{errors.email.message}</p> }
                                </div>

                                <div className="col-sm-6 mb-3">
                                    <div className="form-group">
                                        <label >Phone Number</label>
                                        <input type="tel" className="form-control" id="phone" {...register('phone', {required: fieldError} )} />
                                    </div>
                                    { errors.phone && <p className='text-danger'>{errors.phone.message}</p> }
                                </div>

                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label >Arrival / Pickup place / Hotel / AirB&B</label>
                                        <input type="text" className="form-control" id="airbnb" {...register('airbnb', {required: fieldError} )} value={'Cancun airport'} />
                                    </div>
                                    { errors.airbnb && <p className='text-danger'>{errors.airbnb.message}</p> }
                                </div>
                                {/* ARRIVAL SECTION */}
                                { (resp.type_trip == 1 || resp.type_trip == 2) && <>
                                    <h2>Arrival flight information</h2>
                                    <div className="col-sm-12 mb-3">
                                        <div className="form-group">
                                            <label>Airline</label>
                                            <input type="text" className="form-control" id="airlineArrival" {...register('airlineArrival')} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                            <div className="form-group">
                                                <label>Flight Number</label>
                                                <input type="text" className="form-control" id="flightNumberArrival" {...register('flightNumberArrival')} />
                                            </div>
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <div className="form-group">
                                            <label>Arrival Hour</label>
                                            <TimePick
                                                class='form-control'
                                                intervals='15'
                                                name='arrivalHourArrival' />
                                        </div>
                                    </div></>
                                }
                                
                                {/* DEPARTURE SECTION */}
                                { (resp.type_trip == 1 || resp.type_trip == 3) && <>
                                    <h2>Departure flight information</h2>
                                    <div className="col-sm-12 mb-3">
                                        <div className="form-group">
                                            <label >Airline</label>
                                            <input type="text" className="form-control" id="airlineDeparture" {...register('airlineDeparture')} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <div className="form-group">
                                            <label >Flight Number</label>
                                            <input type="text" className="form-control" id="flightNumberDeparture" {...register('flightNumberDeparture')} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <div className="form-group">
                                            <label >Arrival Hour</label>
                                            <TimePick 
                                                class='form-control'
                                                intervals='15'
                                                name='arrivalHourDeparture'
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='col-sm-12 mb-3'>
                                        <div className='form-group'>
                                            <label>Pickup Time</label>
                                            <TimePick 
                                                class='form-control'
                                                intervals='15'
                                                name='pickupTime'
                                            />
                                        </div>
                                    </div> */}
                                    </>
                                }
                                <div className="col-sm-12 mb-3">
                                    <button type="submit" name="submit" className="btn btn-primary btn-lg col-12"><span>Pay Upon Arrival <i className="fas fa-money-bill-alt"></i></span></button>
                                </div>
                                <div className="col-sm-12 mb-3" id='paypal-button-container'>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
