import React, { useRef, useState, useEffect } from 'react';
import '../../App.css';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Controller, useForm, useFormState } from 'react-hook-form';
import axios from 'axios';
// import {DatePick} from '../lib/DatePick';
import DatePicker from "react-datepicker"; 
import { FormRegistro } from './formRegistro';
import Select from 'react-select';
import { useTranslation } from "react-i18next";



const FormIndex = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {register, formState: {errors}, handleSubmit, control, unregister} = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [datos, setDatos] = useState(null);
    const [form, setForm] = useState({});
    const [topping, setTopping] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isArrived, setIsArrived] = useState(true);
    const [isDeparture, setIsDeparture] = useState(true);
    const [isRoundTrip, setIsRoundTrip] = useState(true);
    useEffect(() =>{
        if(isRoundTrip){
            register('arrived');
            register('departure');
        }

        if(isArrived){
            register('arrived');
        }
        else{
            unregister('arrived');
        }

        if(isDeparture){
            register('departure');
        }
        else{
            unregister('departure');
        }
        
    },[isDeparture, isArrived, isRoundTrip, register, unregister]);
    const getDatos = async () => {
        try{
            const response = await axios.get(process.env.REACT_APP_API_URL+'get');
            setDatos(response.data);
        }
        catch(err){
            const errorMessage = "Error: " + err.message;
            setError(errorMessage);
            console.log(errorMessage);
        }
        finally {
            setIsLoading(false);
        }
        
    }
    useEffect(() => {
        getDatos();
    }, []);
    if (isLoading) return "Loading...";
    if (error) return error;
    
    const onSubmit = async (data) => {
        var serialize = require('form-serialize');
        var form = document.querySelector("#FormReservation");
        var obj = serialize(form, {hash: true});
        const params = {
            type_trip:obj.type_trip,
            passengers:obj.passengers,
            hotel_id:obj.hotels,
            arrival_date:obj.arrived,
            departure_date:obj.departure
        };

        const response = await axios.post(process.env.REACT_APP_API_URL+'createtrip', params)
         .then( (response)=> {
            navigate('/Form', {
                state: {
                    cJSON: response.data,
                }
                
            });
         })
        .catch( (e) => {
            console.log(e);
        });
    }
    const objHotels =
    datos.Hotels.map((hot) => {
        return {'label': hot.name, 'value': hot.id};
    });
    const objPassengers = Array(10).fill(1).map((el, i) =>{
        return{ 'label': (i+1), 'value': (i+1)};
    });
//     let dateComponent = <Controller
//     control={control}
//     name={"departure"}
//     rules={{ validate: {
//             required: (value) => {
//                if(document.getElementsByName('departure')[0].value === '')
//                     return 'Choose a date';
//                 return true;
//             }
//         }
//     }}
//     render={({ field: { onChange, value } }) =>{
//         return(
//             <DatePicker
//                 autoComplete='off'
//                 name="departure"
//                 selected={endDate}
//                 onChange={(date) => setEndDate(date)}
//                 showYearDropdown
//                 startDate={new Date}
//                 endDate={endDate}
//                 minDate={new Date}
//                 placeholderText={t("formIndex.departure")}
//                 className='form-control p-4 validate'
//                 dateFormat={'dd/MM/yyyy'}
//             />
//         );
//     }}
// />;
    const onOptionChange = e => {
        setTopping(e.target.value);
        if(e.target.value === "1"){
            document.getElementById('div_departure').style.display = "block";
            document.getElementById('div_arrival').style.display = "block";
            setIsArrived(true);
            setIsDeparture(true);
        }
        if(e.target.value == "2"){ /* ARRIVED*/
            document.getElementById('div_departure').style.display = "none";
            document.getElementById('div_arrival').style.display = "block";
            setIsArrived(true);
            setIsDeparture(false);
        }

        if(e.target.value == "3"){ /* DEPARTURE */
            document.getElementById('div_arrival').style.display = "none";
            document.getElementById('div_departure').style.display = "block";
            setIsDeparture(true);
            setIsArrived(false);
        }
    }
    return( <>
    <div className="container-fluid booking mt-5 pb-5">
        <div className="container pb-5">
            <div className="bg-light shadow" style={{padding: "30px"}}>
                <div className="row align-items-center" style={{minHeight: "60px"}}>
                    <div className="col-md-12  col-lg-12">
                        <form onSubmit={handleSubmit(onSubmit)} id='FormReservation'>
                            <div className="col-12 d-block pl-0 pr-0 mb-4">
                                <div className='col-12 d-lg-flex pl-0 pr-0'>
                                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>
                                            <input type="radio" 
                                                {...register('type_trip', {required: true})} 
                                                value="1" 
                                                name="type_trip"
                                                checked={topping === "1"}
                                                onChange={onOptionChange}
                                             /> {t("formIndex.round_trip")}
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>
                                            <input type="radio" 
                                                {...register('type_trip', {required: true})} 
                                                value="2" 
                                                name="type_trip" 
                                                checked={topping === "2"}
                                                onChange={onOptionChange}
                                            />   {t("formIndex.one_way_airport_hotel")}
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>
                                            <input type="radio" 
                                                {...register('type_trip', {required: true})} 
                                                value="3" 
                                                name="type_trip"
                                                checked={topping === "3"}
                                                onChange={onOptionChange}
                                             />   {t("formIndex.one_way_hotel_airport")}
                                        </label>
                                    </div>
                                </div>
                                {errors.type_trip && <div className="col-lg-4 col-md-12 col-sm-12 col-12"><p className='text-danger'>Choose an option</p></div>}
                            </div>
                            <div className="col-12 d-lg-flex d-block pl-0 pr-0">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                    <div className="mb-3 mb-md-0">
                                        <Controller
                                            control={control}
                                            defaultValue='DEFAULT'
                                            name="hotels"
                                            autocomplete="off"
                                            rules={{ validate: (value, formValues) => value != 'DEFAULT' || 'Choose an option'} }
                                            render={({ field, value, name, ref }) => (
                                                <Select
                                                    placeholder={'Hotel'}
                                                    className='basic-single'
                                                    classNamePrefix="select"
                                                    defaultValue={''}
                                                    inputRef={field.ref}
                                                    name={field.name}
                                                    options={objHotels}
                                                    value={objHotels.find((c) => c.value === field.value)}
                                                    onChange={val => field.onChange(val.value)}
                                                />
                                            )}
                                        />
                                        { errors.hotels && <p className='text-danger'>{errors.hotels.message}</p> }
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-12 col-sm-12 col-12" id="div_arrival">
                                    <div className="mb-3 mb-md-0">
                                        <div className="date" data-target-input="nearest">
                                            {isArrived ? (
                                                <Controller
                                                    control={control}
                                                    name={'arrived'}
                                                    rules={{ validate: {
                                                            required: (value) => {
                                                               if(document.getElementsByName('arrived')[0].value == '')
                                                                    return 'Choose a date';
                                                                return true;
                                                            }
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) =>{
                                                        return(
                                                            <DatePicker
                                                                autoComplete='off'
                                                                name="arrived"
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                startDate={startDate}
                                                                showYearDropdown
                                                                placeholderText={t("formIndex.arrival")}
                                                                className='form-control p-4 validateSIII'
                                                                minDate={new Date()}
                                                                dateFormat={'dd/MM/yyyy'}
                                                            />
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <Controller
                                                    control={control}
                                                    name={'arrived'}
                                                    render={({ field: { onChange, value } }) =>{
                                                        return(
                                                            <DatePicker
                                                                name="arrived"
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                startDate={startDate}
                                                                showYearDropdown
                                                                placeholderText='Arrived'
                                                                className='form-control p-4'
                                                                minDate={new Date()}
                                                                dateFormat={'dd/MM/yyyy'}
                                                            />
                                                        );
                                                    }}
                                                />
                                            )}
                                            
                                        </div>
                                        { errors.arrived && <p className='text-danger'>{errors.arrived.message}</p> }
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-12 col-md-12 col-12" id="div_departure">
                                    <div className="mb-3 mb-md-0">
                                        <div className="date" data-target-input="nearest">
                                            {/* {dateComponent} */}
                                            {(isDeparture) ? (
                                                <Controller
                                                    control={control}
                                                    name={"departure"}
                                                    rules={{ validate: {
                                                            required: (value) => {
                                                               if(document.getElementsByName('departure')[0].value == '')
                                                                    return 'Choose a date';
                                                                return true;
                                                            }
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) =>{
                                                        return(
                                                            <DatePicker
                                                                name="departure"
                                                                selected={endDate}
                                                                onChange={(date) => setEndDate(date)}
                                                                showYearDropdown
                                                                startDate={new Date}
                                                                endDate={endDate}
                                                                minDate={new Date()}
                                                                placeholderText={t("formIndex.departure")}
                                                                className='form-control p-4 validate'
                                                                dateFormat={'dd/MM/yyyy'}
                                                            />
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <Controller
                                                    control={control}
                                                    name={"departure"}
                                                    render={({ field: { onChange, value } }) =>{
                                                        return(
                                                            <DatePicker
                                                                name="departure"
                                                                selected={endDate}
                                                                onChange={(date) => setEndDate(date)}
                                                                startDate={endDate}
                                                                showYearDropdown
                                                                placeholderText='Arrived'
                                                                className='form-control p-4'
                                                                minDate={new Date()}
                                                                
                                                            />
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>
                                        { errors.departure && <p className='text-danger'>{errors.departure.message}</p> }
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                                    <div className="mb-3 mb-md-0">
                                        <Controller
                                            control={control}
                                            defaultValue='DEFAULT'
                                            name="passengers"
                                            rules={{ validate: (value, formValues) => value != 'DEFAULT' || 'Choose an option'} }
                                            render={({ field, value, name, ref }) => (
                                                <Select
                                                    placeholder={t("formIndex.passenger")}
                                                    className='basic-single'
                                                    classNamePrefix="select"
                                                    defaultValue={''}
                                                    inputRef={field.ref}
                                                    name={field.name}
                                                    options={objPassengers}
                                                    value={objPassengers.find((c) => c.value === field.value)}
                                                    onChange={val => field.onChange(val.value)}
                                                />
                                            )}
                                        />
                                        { errors.passengers && <p className='text-danger'>{errors.passengers.message}</p> }
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-2">
                                    <button className="btn btn-primary btn-block" type="submit" style={{height: "47px", marginTop: "-2px"}}>{t("formIndex.btn_continue")}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default FormIndex;