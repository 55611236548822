import React, { Component, useEffect } from "react";
import { Topbar } from "../topbar";
import { Banner } from "../banner";
import { useTranslation } from "react-i18next";
import { Footer } from "../footer";
import imgWhatsap from "../../imagenes/whatsapp.png";

export const Adittionals = () => {
    const { t } = useTranslation();
    return(
        <>
            <Topbar />
            <Banner />
            <div className="container mt-5">
                <div className="contact__wrapper shadow-lg mt-n9 pt-4 pb-4 p-1 p-lg-3">
                    <h2 className="text-center text-primary">{t("adittional.title")}</h2>
                    <ul className="list-style--none pl-0">
                        <li><p className="text-center">
                            {/* <i className="text-primary fa fa-check-circle"></i> */}
                            {/* {t("adittional.contact")}  */}
                            <a aria-label="Chat on WhatsApp" href="https://wa.me/5219988446385">
                                <img alt="Chat on WhatsApp" className="img-whatsapp" src={imgWhatsap} />
                            </a>
                        </p></li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
}