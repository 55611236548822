import React, { Component } from "react";

export class Carts extends Component {
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <div className="container-fluid pt-5">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-md-6 float-left">
                                <img src={require("../../imagenes/cars1.JPG")} className="resize-auto" />
                            </div>
                            <div className="col-md-6 float-right">
                                <img src={require("../../imagenes/cars2.JPG")} className="resize-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
