import React from "react";
import { Topbar } from "../topbar";
import { Banner } from "../banner";
import { Footer } from "../footer";
import { useTranslation } from "react-i18next";

export function About(){
    const { t } = useTranslation();
    return(
        <>
        <Topbar />
        <Banner />
        <div className="container mt-5">
            <div className="contact__wrapper shadow-lg mt-n9 pt-4 pb-4">
                <h2 className="text-center text-primary">{t("about.why_choose")}</h2>
                <ul className="list-style--none">
                    <li><p><i className="text-primary fa fa-check-circle"></i>{t("about.travel_insurances")}</p></li>
                    <li><p><i className="text-primary fa fa-check-circle"></i>{t("about.taxes_includes")}</p></li>
                    <li><p><i className="text-primary fa fa-check-circle"></i>{t("about.service_24_7")}</p></li>
                    <li><p><i className="text-primary fa fa-check-circle"></i>{t("about.reception_cancun_airport")}</p></li>
                    <li><p><i className="text-primary fa fa-check-circle"></i>{t("about.monitoring_your_flight")}</p></li>
                    <li><p><i className="text-primary fa fa-check-circle"></i>{t("about.service_cancun")}</p></li>
                    <li><p><i className="text-primary fa fa-check-circle"></i>{t("about.reception_cancun")}</p></li>
                </ul>
            </div>
        </div>
        <Footer />
        </>
    );
}