import './App.css';
import { Topbar } from './components/topbar';
import { Banner } from './components/banner';
import { Carousel } from './components/carousel';
import { Footer } from './components/footer';
import FormIndex from './components/forms/formIndex';
import { Destination } from './components/destination';
import { ButtonUp } from './components/button_up';
import { Carts } from './components/sections/cars';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n, ready } = useTranslation();
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  if(ready){
    return (
      <div className="App">
          <Topbar />
          <Banner />
          <Carousel />
          <FormIndex />
          <Carts />
          {/* <Destination /> */}
          <ButtonUp />
          <Footer />
      </div>
    );
  }
  return <span>Loading...</span>;
}

export default App;
