import { Banner } from "../banner";
import { Topbar } from "../topbar";

export function Error(){
    return(
        <>
            <Topbar />
            <Banner />
            <div className="container mt-5">
                <p>Algo salió mal</p>
            </div>
        </>
    );
}