import React from "react";
import { Topbar } from "../topbar";
import { Footer } from "../footer";
export function Terms(props) {
    return(
        <>
            <Topbar />
            <div className="container mt-5">
                <h1>Terms</h1>
                <p>
                    ARRIVAL AND DEPARTURE INFORMATION:
                </p>
                <p>

                    OUR STAFF WILL BE OUTSIDE THE BUILDING HOLDING A SIGN WITH YOUR NAME & OUR LOGO ON IT. DO NOT ACCEPT “Help or assistance” from other people except our staff. if you arrive on terminal “4” GO DIRECTLY TOWARD THE BUS AREA, BY THE WELCOME BAR BEHIND THE MINI-MARKET. If you arrive on terminal 3 our staff will meet you BY THE WELCOME BAR MARGARITAVILLE, Gratuities for the staff & driver are not included & are totally at your discretion
                </p>
                <p>
                    We would like to remind you that our services are private, however they are also based on time limits due to logistics. Therefore in case of any delay arriving or leaving the resorts or airbnb, do contact us ASAP dialing the number listed below. If we don’t hear from you 50 minutes after ARRIVAL or 20 minutes prior to DEPARTURE. Your service will be call for No Show. NO REFUNDS will not apply for NO SHOWS. do contact us ASAP dialing the number listed below. If we don’t hear from 50 minutes after ARRIVAL or 20 minutes prior to DEPARTURE. Your service will be call for No Show. NO REFUNDS will apply for NO SHOWS.
                </p>
                <p>
                    CALL, TEXT OR WHATSAPP (24/7) AT: +52 998 844 6385
                    EMAIL: info@altripscancun.com
                    THANK YOU FOR CHOOSING US, WELCOME TO THE MEXICAN CARIBBEAN
                </p>
                <p>
                    *Cancelations Policy:
                    49 Hours or more prior to date service- full refund.
                    0-48 hours prior to date of service or no-show 100% non-refundable*
                </p>
            </div>
            <Footer />
        </>
    );
}