import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { Controller, useForm } from "react-hook-form";
import '../styles/banner.css';

export function Banner(){
  const {register, formState: {errors}, handleSubmit, control} = useForm();
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState((i18n.language == "es") ? "MX" : "US");
    console.log(selected);
    const changeLanguage = (lng) => {
        var language = "";
        if(lng == "MX")
            language = "es";
        else
            language = "en";
        i18n.changeLanguage(language);
    };
  return(
    <div className="container-banner container-fluid position-relative nav-bar p-0">
      <div className="container-lg position-relative p-0 px-lg-3" style={{ zIndex: '9'}} >
        <nav className="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-3 pl-lg-5">
        <div className="col-lg-3 text-right p-0 d-block d-none d-lg-none">
            <Controller 
                name="country"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ReactFlagsSelect
                    fullWidth={false}
                    countries={["US", "MX"]}
                    selected={selected}
                    onSelect={code => { 
                      setSelected(code);
                      changeLanguage(code);
                    }}
                    placeholder={t("select_language")}
                    showOptionLabel={false}
                    showSelectedLabel={false}
                    value={value}
                  />
                )}
              />
          </div>
          <a href="/" className="navbar-brand">
              <img className="img-banner d-none d-md-block" src={require("../imagenes/altrips-01.jpg")} alt="altrips" />
              <img className="img-banner d-md-none d-block" src={require("../imagenes/altrips-movil.jpg")} alt="altrips" />
          </a>
          <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>
          
            <div className="collapse navbar-collapse justify-content-between px-4" id="navbarCollapse">
              <div className="navbar-nav ml-auto py-0">
                <Link to="/" className="nav-item nav-link">{t("banner.home")}</Link>
                <Link to="/Adittionals" className="nav-item nav-link">{t("banner.aditional")}</Link>
                <Link to="/About" className="nav-item nav-link">{t("banner.about")}</Link>
              </div>
            </div>
          
        </nav>
      </div>
    </div>
  );
}