import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/index.css';
import App from './App';
import { FormRegistro } from './components/forms/formRegistro';
import { About } from './components/sections/about';
import { ReservationDetails } from './components/sections/reservationDetails';
import { Terms } from './components/sections/terms';
import { Adittionals } from './components/sections/adittionals';
import { Error } from './components/sections/error';
import '../src/components/lib/i18n';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />
  },
  {
    path: "/atc_app",
    element: <App />,
    errorElement: <Error />
  },
  {
    path: "/Form",
    element: <FormRegistro />,
    errorElement: <Error />
  },
  {
    path: "/About",
    element: <About />,
    errorElement: <Error />
  },
  {
    path: "/Reservation",
    element: <ReservationDetails />,
    errorElement: <h1>Error</h1>
  },
  {
    path: "Terms",
    element: <Terms />,
    errorElement: <h1>Error</h1>
  },
  {
    path: "/Adittionals",
    element: <Adittionals />,
    errorElement: <Error />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);