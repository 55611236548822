import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { Controller, useForm } from "react-hook-form";

export function Topbar(){
    const {register, formState: {errors}, handleSubmit, control} = useForm();
    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState((i18n.language == "es") ? "MX" : "US");
    console.log(selected);
    const changeLanguage = (lng) => {
        var language = "";
        if(lng == "MX")
            language = "es";
        else
            language = "en";
        i18n.changeLanguage(language);
    };
    return(
        <div className="container-fluid bg-light pt-3 d-none d-lg-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 text-center text-lg-left mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center">
                            <p><i className="fa fa-envelope mr-2"></i>info@altripscancun.com</p>
                            <p className="text-body px-3">|</p>
                            <p><i className="fa fa-phone-alt mr-2"></i><a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=5219988446385&text=Buenas tardes, deseo más información">+52 1 998 844 6385</a></p>
                        </div>
                    </div>
                    <div className="col-lg-3 text-center">
                        <Controller 
                            name="country"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <ReactFlagsSelect
                                    fullWidth={false}
                                    countries={["US", "MX"]}
                                    customLabels={{ US: "ENGLISH", MX: "ESPAÑOL" }}
                                    selected={selected}
                                    onSelect={code => { 
                                        setSelected(code);
                                        changeLanguage(code);
                                    }}
                                    placeholder={t("select_language")}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    <div className="col-lg-4 text-center text-lg-right">
                        <div className="d-inline-flex align-items-center">
                            <a className="text-primary px-3" target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61550490430180">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a className="text-primary px-3" href="">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a className="text-primary px-3" target="_blank" rel="noreferrer" href="https://instagram.com/altripscancun?igshid=OGQ5ZDc2ODk2ZA==">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a className="text-primary pl-3" href="">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}