import React, { useState } from "react";
import TimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function TimePick(props){
    const [startDate, setStartDate] = useState(new Date());

    return (
        <>
            <TimePicker 
                className={props.class}
                name={props.name}
                selected={startDate}
                onChange={date => setStartDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={props.intervals}
                timeCaption="Time"
                dateFormat="h:mm aa"
            />
        </>
    );
}